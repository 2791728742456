<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active"><a href="#">Home</a></li>
          <li><a href="javascript:void(0);">Login</a></li>
        </ul>
      </div>
    </div>
    <section class="section--login">
      <div class="container">
        <div class="row">
          <div class="col-sm-6 offset-sm-3 col-lg-6 form-group--block">
            <div class="login__box">
              <div class="login__header">
                <h3 class="login__login">Forgot</h3>
              </div>
              <div class="login__content">
                <div class="login__label">Fill out your email address, and we’ll send you instructions to forgot your password. </div>
                <form @submit.prevent="onSubmit()">
                  <div class="from-group">
                    <input
                      class="form-control"
                      :class="{'is-invalid': $errors.has('email')}"
                      type="email"
                      placeholder="Enter the email"
                      v-model="form.email"
                    />
                    <div
                      class="invalid-feedback"
                      v-show="$errors.has('email')"
                    >
                      {{ $errors.first('email') }}
                    </div>
                  </div>

                  <button class="btn btn-login" type="submit">Reset Password</button>
                </form>

                  <div style="font-size:14px" class="text-center">
                    <router-link :to="{ name: 'home' }">
                      Back to Hompage
                    </router-link>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
	data() {
		return {
			form: {
				email: null,
			},
			onSubmited: false,
		};
	},

	methods: {
		onSubmit() {
			this.onSubmited = true;
			this.$store
				.dispatch("auth/forgotPassword", this.form)
				.then(() => {
					this.resetForm();
					this.$router.push({name: 'forgot-message'});
				})
				.catch(() => {
					this.onSubmited = false;
				});
		},

		resetForm() {
			var self = this;
			Object.keys(this.form).forEach(function (key) {
				self.form[key] = null;
			});
			this.onSubmited = false;
		},
	},
};
</script>